import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "基本概念"
    }}>{`基本概念`}</h1>
    <p>{`如果你已经是 `}<a parentName="p" {...{
        "href": "https://redux-observable.js.org"
      }}>{`redux-observable`}</a>{` 的用户，你会对 `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` 的一些概念感到非常熟悉。`}</p>
    <p><strong parentName="p">{`我们甚至从 redux-observable 的文档里面复制了一些内容`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      